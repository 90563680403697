import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import {Container, Row, Col} from "react-bootstrap";
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import SignupData from "../../../content/signup/index.json";
import TickerData from "../../../content/home/ticker.json";
import HeroPagesCenter from "../../../components/heroes/hero-pages-center";
import ClientsTicker from "../../../components/misc/clients-ticker";
import SignupForm from "../../../components/signup/form";
import SelectedPlan from "../../../components/signup/plan";
import SimpleCTA from "../../../components/ctas/simple-cta";

const SignupMorePage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={SignupData[intl.locale].title}
             description={SignupData[intl.locale].text}
             pathname={location.pathname}
        />
        <HeroPagesCenter
            theme={`primary`}
            content={SignupData[intl.locale]}
        />
        <div className={`signup`}>
            <Container>
                <Row noGutters>
                    <Col md={4}>
                        <div className={`top-small-overflow-wrapper`}>
                            <SelectedPlan plan={`Brutal`} />
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className={`top-overflow-wrapper`}>
                            <SignupForm plan={`Brutal`} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={`mb-5`}>
                <Container>
                    <SimpleCTA theme={`primary`} content={SignupData[intl.locale].cta} />
                </Container>
            </div>
            <ClientsTicker content={TickerData[intl.locale]} />
        </div>
    </Layout>
)

export default injectIntl(SignupMorePage)
